const danishStrings = {
    YourShipmentHasBeenDelivered:'Din sending er blevet leveret.',
    DeliveredBy:'Leveret Af',
    RateYourExperience:'Hvordan Bedømmer Du Din Erfaring?',
    ThankYouForYourValuableFeedback:'Tak for din værdifulde feedback.',
    YourFeedbackWillContinuallyHelpUsImproveOurServices:'Din feedback vil hele tiden hjælpe os med at forbedre vores tjenester.',
    DeliveryFailed:'Levering Mislykkedes',
    AddDeliveryPreferences:'Tilføj Levering Præference',
    YourDeliveryPreferenceAreSavedSuccessFully:'Din leveringspræference gemmes med succes',
    WeAreUnableToTrackYourShipmentRightNow:'Vi kan ikke spore din sending lige nu.',
    PleaseUpdateLocationInsideCircle:'Opdaterer venligst placering inde i cirklen',
    Ok:'OK',
    Update:'Opdatering',
    PickCurrentLocation:'Vælg nuværende sted',
    SearchFor:'Søg efter',
    ThisSchedulingLinkHasExpired:'Dette skemalægnings link er udløbet',
    WeWillShareANewLinkWithYouShortly:'Vi deler snart et nyt link med dem.',
    UhHo:'Uh ho!',
    NeedHelp:'Har Du Brug For Hjælp?',
    CallSupport:'Call-Understøttelse',
    EmailSupport:'E-Mail-Understøttelse',
    DeliveryAt:'Levering ved',
    DeliveredAt: 'Leveret ',
    ContactNo:'Kontaktnummer',
    TrackOnMap:'Spor på kort',
    MessageToExecutive:'Meddelelse til den udøvende magt',
    Cancel:'Annullere',
    Send:'Sende',
    Executive:'Executive',
    Comments:'Bemærkninger (fakultativ))',
    SubmitFeedback:'INDSEND FEEDBACK',
    TrackingNo:'Sporingsnummer',
    Details:'Information',
    Characters:'Tegn',
    Updating:'opdaterer…',
    OopsSomethingWentWrongPleaseTryAfterSomeTime:'Ups, noget gik galt prøv venligst om lidt tid',
    NotListedAboveSendACustomMessage:'Ikke angive ovenfor, send en tilpasset meddelelse',
    ChangeDeliveryLocation:'Ændr leveringssted',
    AttemptedBy:'Forsøgt leveret af',
    DeliveredTo:'Leveret til',
    SendMessage:'SEND MEDDELELSE',
    VIEW_DETAILS:'Vis oplysninger',
    SAVE_LOCATION:'GEM STED',
    LOCATION:'sted',
    SEARCH_ADDRESS:'Søg efter adresse',
    OpenHours:'Åbningstider',
    Note:'Bemærkning',
    Address:'Adresse',
    Nearest: 'Nærmest',
    ContactNumber:'Kontaktnummer',
    FAQS:'Ofte stillede spørgsmål',
    HowManyTimesCanIChangeMyPickUpPoint: 'Hvor mange gange kan jeg ændre afhentningssted?',
    YouCanChangeItOnly: 'Du kan kun ændre det',
    GoBack:'Gå tilbage',
    SetAsPickupStore:'Vælg som afhentningssted',
    MapView:'Kortvisning',
    SearchForPickupPointNearYou:'Søg efter afhentningssteder tæt på dig',
    Arriving:'Ankommer',
    LiveTrackingWillStart:'Live sporing starter',
    DriverTrackerWillGetActivatedOnceTheyAreOnWay:  'Chaufførens sporingsanordning aktiveres, når de er på vej',
    TrackerTimeline:'Sporingstidslinje',
    PM:'PM',
    AM:'AM',
    TodayAt:'i dag kl.',
    TomorrowAt:'i morgen kl.',
    Today:'I dag',
    Tomorrow:'I morgen',
    On:'på',
    In:'i',
    Soon:'snart',
    Min:'min',
    Mins:'min',
    Items:'VARER',
    ViewDetails: 'Vis oplysninger',
    DeliveryInformation:'Leveringsoplysninger',
    TrackingHistory:'Sporingshistorik',
    StayConnected:'Forbliv tilsluttet',
    YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'Du har allerede forsøgt at foretage en betaling inden for de sidste par minutter. Vil du annullere tidligere transaktioner',
    No: 'NEJ',
    yes:'JA',
    YourPackageWillBeDeliveredBy : 'Din pakke vil blive leveret den',
    OrderDetails: 'Ordreoplysninger',
    OrderInformation:'Ordreinformation',
    LastAttempted:'Sidst forsøgt',
    DeliveryBy:'Levering af',
    Pickuppointisupdated:'Afhentningspunkt er opdateret',
    MsgToFePageHeader:'Send leveringsinstruktioner',
    MsgToFeSupportingText:'Hvad vil du fortælle chaufføren?',
    AddYourMsgHere:'Tilføj din meddelelse her',
    InstructionsSentSuccessfully:'Instruktioner er sendt',
    SomethingWentWrongPlsTryAgain:'Der skete en fejl. Prøv igen.',
    times:"gange",
    TrackMovementInRealTime:"Følg bevægelser i realtid",
    Hours:"hours",
    Hour:"hour",
    WriteYourFeedbackOptional:"Skriv din feedback (valgfrit)",
    ReturnOrder: "Return Order",
    TheLinkYouAreTryingToOpenHasExpired: "Linket, du forsøger at åbne, er udløbet    ",
    SelectAnAvailableTimeSlot:"Select an available time slot",
    January:"JANUARY",
    February:"FEBRUARY",
    March :"MARCH",
    April:"APRIL",
    May:"MAY",
    June:"JUNE",
    July:"JULY",
    August:"AUGUST",
    September:"SEPTEMBER",
    October:"OCTOBER",
    November:"NOVEMBER",
    December:"DECEMBER",
    Monday:"MONDAY",
    Tuesday:"TUESDAY",
    Wednesday:"WEDNESDAY",
    Thursday:"THURSDAY",
    Friday:"FRIDAY",
    Saturday:"SATURDAY",
    Sunday:"SUNDAY",
    ViewTimings: "View Timings",
    HideTimings: "Hide Timings",
    PleaseAnswerAllMandatoryQuestions:"Besvar venligst alle obligatoriske spørgsmål",
    HelpLineLink: 'Helpline Link',
    ShipmentOf: "Forsendelse%af",
}
export default danishStrings;
