const malayStrings = {
    YourShipmentHasBeenDelivered:'Kiriman anda telah dihantar',
    DeliveredBy:'Dihantar Oleh',
    RateYourExperience:'Nilaikan Pengalaman Anda',
    ThankYouForYourValuableFeedback:'Terima kasih untuk maklum balas bernilai anda',
    YourFeedbackWillContinuallyHelpUsImproveOurServices:'Maklum balas anda akan secara berterusan membantu kami menambah baik perkhidmatan kami',
    DeliveryFailed:'Penghantaran Gagal',
    AddDeliveryPreferences:'Tambah Pilihan Penghantaran',
    YourDeliveryPreferenceAreSavedSuccessFully:'Pilihan penghantaran anda telah berjaya disimpan',
    WeAreUnableToTrackYourShipmentRightNow:'Kami tidak dapat menjejaki kiriman anda sekarang',
    PleaseUpdateLocationInsideCircle:'Sila kemas kini lokasi di dalam bulatan',
    Ok:'OK',
    Update:'Kemas kini',
    PickCurrentLocation:'Pilih lokasi semasa',
    SearchFor:'Carian untuk',
    ThisSchedulingLinkHasExpired:'Pautan penjadualan ini telah luput',
    WeWillShareANewLinkWithYouShortly:'Kami akan kongsikan pautan yang baharu sebentar lagi',
    UhHo:'Uh ho!',
    NeedHelp:'Perlukan Bantuan?',
    CallSupport:'Hubungi Sokongan',
    EmailSupport:'E-mel Sokongan',
    DeliveryAt:'Dihantar pada',
    DeliveredAt: 'Dihantarkan Pada',
    ContactNo:'Nombor Hubungan',
    TrackOnMap:'Jejak pada Peta',
    MessageToExecutive:'Pesanan kepada Eksekutif',
    Cancel:'Batal',
    Send:'Hantar',
    Executive:'Eksekutif',
    Comments:'Komen(pilihan)',
    SubmitFeedback:'HANTAR MAKLUM BALAS',
    TrackingNo:'No. Jejakan',
    Details:'Butiran',
    Characters:'Aksara',
    Updating:'mengemas kini.....',
    OopsSomethingWentWrongPleaseTryAfterSomeTime:'Op! Ada sesuatu yang tidak kena, sila cuba sebentar lagi',
    NotListedAboveSendACustomMessage:'Tidak disenaraikan di atas, hantarkan mesej tersuai',
    ChangeDeliveryLocation:'Tukar Lokasi Penghantaran',
    AttemptedBy:'Dicuba oleh',
    DeliveredTo:'Dihantar Ke',
    SendMessage:'HANTAR MESEJ',
    VIEW_DETAILS:'Lihat butiran',
    SAVE_LOCATION:'SIMPAN LOKASI',
    LOCATION:'lokasi',
    SEARCH_ADDRESS:'Cari Alamat',
    OpenHours:'Waktu Buka',
    Note:'Nota',
    Address:'Alamat',
    Nearest: 'Terdekat',
    ContactNumber:'Nombor Telefon',
    FAQS:'Soalan Lazim',
    HowManyTimesCanIChangeMyPickUpPoint: 'Berapa kali saya boleh menukar titik pengambilan saya?',
    YouCanChangeItOnly: 'Anda hanya boleh menukar',
    GoBack:'Kembali',
    SetAsPickupStore:'Tetapkan sebagai Gedung Pengambilan',
    MapView:'Pandangan Peta',
    SearchForPickupPointNearYou:'Cari titik Pengambilan berdekatan anda',
    Arriving:'Hampir tiba',
    LiveTrackingWillStart:'Penjejakan langsung akan bermula',
    DriverTrackerWillGetActivatedOnceTheyAreOnWay:  'Penjejak pemandu akan diaktifkan sebaik sahaja mereka dalam perjalanan',
    TrackerTimeline:'Garis Masa Penjejak',
    PM:'MLM',
    AM:'PG',
    TodayAt:'hari ini pada',
    TomorrowAt:'esok pada',
    Today:'Hari Ini',
    Tomorrow:'esok pada',
    On:'pada',
    In:'dalam',
    Soon:'tidak lama lagi',
    Min:'min',
    Mins:'min',
    Items:'ITEM',
    ViewDetails: 'Lihat Butiran',
    DeliveryInformation:'Maklumat Penghantaran',
    TrackingHistory:'Sejarah Penjejakan',
    StayConnected:'Kekal Terhubung',
    YouHaveAlreadyAttemptedToMakeAPaymentInLastFewMinutes: 'Anda telah cuba membuat pembayaran dalam beberapa minit yang lepas. Adakah anda ingin membatalkan transaksi',
    No: 'TIDAK',
    yes:'YA',
    YourPackageWillBeDeliveredBy : 'Bungkusan anda akan dihantar pada',
    OrderDetails: 'Butiran Pesanan',
    OrderInformation:'Maklumat Pesanan',
    LastAttempted:'Percubaan Yang Lepas',
    DeliveryBy:'Penghantaran Oleh',
    Pickuppointisupdated:'Pickup Point dikemas kini',
    MsgToFePageHeader:'Kirimkan Arahan Penghantaran',
    MsgToFeSupportingText:'Apakah yang anda mahu saya beritahu pemandu?',
    AddYourMsgHere:'Masukkan mesej anda di sini',
    InstructionsSentSuccessfully:'Arahan Berjaya Dikirimkan',
    SomethingWentWrongPlsTryAgain:'Ralat berlaku. Cuba lagi.',
    times:"kali",
    TrackMovementInRealTime:"Jejaki Pergerakan Dalam Masa Nyata",
    Hours:"hours",
    Hour:"hour",
    WriteYourFeedbackOptional:"Tulis maklum balas anda (Pilihan)",
    ReturnOrder: "Return Order",
    TheLinkYouAreTryingToOpenHasExpired: "Pautan yang anda cuba buka telah tamat tempoh ",
    SelectAnAvailableTimeSlot:"Select an available time slot",
    January:"JANUARY",
    February:"FEBRUARY",
    March :"MARCH",
    April:"APRIL",
    May:"MAY",
    June:"JUNE",
    July:"JULY",
    August:"AUGUST",
    September:"SEPTEMBER",
    October:"OCTOBER",
    November:"NOVEMBER",
    December:"DECEMBER",
    Monday:"MONDAY",
    Tuesday:"TUESDAY",
    Wednesday:"WEDNESDAY",
    Thursday:"THURSDAY",
    Friday:"FRIDAY",
    Saturday:"SATURDAY",
    Sunday:"SUNDAY",
    ViewTimings: "View Timings",
    HideTimings: "Hide Timings",
    PleaseAnswerAllMandatoryQuestions:"Sila jawab semua soalan wajib",
    HelpLineLink: 'Helpline Link',
    ShipmentOf: "Penghantaran%dari",
}
export default malayStrings